import React from "react"
import styled from "styled-components"
import { Title1, Title2, Title3, SmallText, Text } from "../assets/primitives"
import { Container, Row, Col } from "react-grid-system"
import Strings from "../assets/strings"
import { StyledInput } from "../view/contactBox"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { SectionLight } from "../assets/styles"
import { BoxStyle } from "../view/contactBox"
import PrimaryButton from "../view/button"
import axios from "axios"

export default class VerifyMail extends React.PureComponent {
  state = {
    password: "",
    passwordRepeat: "",
    status: "",
    statusMessage: "",
  }

  componentDidMount() {
    this._sendResetPassword()
  }

  _extractGetParams = queryString => {
    var query = {}
    var pairs = (queryString[0] === "?"
      ? queryString.substr(1)
      : queryString
    ).split("&")
    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split("=")
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "")
    }
    return query
  }

  _sendResetPassword = () => {
    const params = this._extractGetParams(this.props.location.search)
    axios
      .post("https://brive-backend.com/auth/verify-mail", {
        token: params.token,
        id: params.id,
      })
      .then(
        this.setState({
          status: "success",
          statusMessage: "Deine E-Mail-Adresse wurde bestätigt.",
        })
      )
      .catch(e => {
        console.log(e)
        this.setState({
          status: "error",
          statusMessage: "Es gab einen Fehler: " + e,
        })
      })
  }

  _renderStatus = () => {
    if (this.state.status === "") {
      return (
        <>
          <Text>Wird geladen...</Text>
        </>
      )
    } else if (this.state.status === "success") {
      return (
        <>
          <Text>{this.state.statusMessage}</Text>
        </>
      )
    } else if (this.state.status === "error") {
      return (
        <>
          <Text>{this.state.statusMessage}</Text>
        </>
      )
    }
  }

  render() {
    console.log(this)
    return (
      <Layout>
        <SectionLightExt>
          <SEO title={Strings.verifyMail.title} />
          <Container>
            <BoxStyle>
              <Title1Ext>{Strings.verifyMail.title}</Title1Ext>
              {this._renderStatus()}
            </BoxStyle>
          </Container>
        </SectionLightExt>
      </Layout>
    )
  }
}

const Title1Ext = styled(Title1)`
  margin-bottom: 2rem;
`

const StyledInputExt = styled(StyledInput)`
  margin-bottom: 0rem;
`

const SectionLightExt = styled(SectionLight)`
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
`

const SmallTextExt = styled(SmallText)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`
